import React from 'react'
import { AboutCompo } from '../../components/About/About'
import './About.scss'

function About() {
  return (
    <div>
        <AboutCompo/>
    </div>
  )
}

export default About